import ReactDOM from 'react-dom/client';
import React from 'react';
import App from '../components/App';
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

const root = document.getElementById("root");
if (root) {
  ReactDOM.createRoot(root).render(
    <App />
  );
}
